import clsx from "clsx";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { codeStatuses, displayModes } from "../../../../constants";
import { getSelectedDisplayMode } from "../../../../redux/App/appSelectors";
import {
  getBoxesStats,
  getBoxesTable,
  getPalletsStats,
  getPalletsTable,
  getProductsStats,
  getProductsTable,
  getSetsTableItems,
} from "../../../../redux/Line/lineSelectors";
import TableRow from "./components/TableRow/TableRow";
import "./ResultsTable.module.scss";
import s from "./ResultsTable.module.scss";
import { FixedSizeList as List } from "react-window";
import { getLineStatus } from "../../../../redux/Line/lineSelectors";
import TooggleButton from "components/Common/TooggleButton/TooggleButton";

const STATUS_LIMIT = 100;



const ResultsTable = ({ resetTableFuncs, showPhotos }) => {
  const selectedDisplayingMode = useSelector(getSelectedDisplayMode);

  const [validItems, setValidItems] = useState([]);
  const [errorItems, setErrorItems] = useState([]);
  const [dropoutItems, setDropoutItems] = useState([]);
  const [notValidItems, setNotValidItems] = useState([]);

  const [validBoxes, setValidBoxes] = useState([]);
  const [errorBoxes, setErrorBoxes] = useState([]);
  const [notValidBoxes, setNotValidBoxes] = useState([]);

  const [validPallets, setValidPallets] = useState([]);
  const [errorPallets, setErrorPallets] = useState([]);
  const [notValidPallets, setNotValidPallets] = useState([]);

  const [stats, setStats] = useState({});
  const [tableItems, setTableItems] = useState([]);

  const [checked, setChecked] = useState("all");
  const lineStatus = useSelector(getLineStatus);
  const setsTableItems = useSelector(getSetsTableItems);
  const [lastAddedItem, setLastAddedItem] = useState(null);

  const productsTableItems = useSelector(getProductsTable);
  const boxesTableItems = useSelector(getBoxesTable);
  const palletsTableItems = useSelector(getPalletsTable);
  const productsStats = useSelector(getProductsStats);
  const boxesStats = useSelector(getBoxesStats);
  const palletsStats = useSelector(getPalletsStats);

  const addStatusToArray = (statusArray, setStatusArray, newStatus, setValidItems, validItems) => {
    console.log(validItems?.some((item) =>
      item?.Code == newStatus?.Code &&
      item?.Status === 'SUCCESS' &&
      newStatus?.Status === 'DROPOUT'
    ));

    if (validItems?.some((item) =>
      item?.Code == newStatus?.Code &&
      item?.Status === 'SUCCESS' &&
      newStatus?.Status === 'DROPOUT'
    )) {

      console.log('работает удаление');
      setValidItems((prevValidArray) =>
        prevValidArray.filter(item => item.Code !== newStatus.Code)
      );
    }
    setStatusArray((prevArray) => {
      if (prevArray?.some((item) => item?.Id == newStatus?.Id)) {
        return prevArray;
      }
      const updatedArray = [newStatus, ...prevArray]?.slice(0, STATUS_LIMIT);
      return updatedArray;
    });
  };

  const tabsInfo = useMemo(
    () => {
      const baseInfo = {
        [displayModes.ITEMS]: {
          stats: { ...productsStats },
          table: [...productsTableItems, ...setsTableItems],
        },
        [displayModes.BOXES]: {
          stats: boxesStats,
          table: boxesTableItems,
        },
        [displayModes.PALLETS]: {
          stats: palletsStats,
          table: palletsTableItems,
        },
      };
      return baseInfo;
    },
    [
      productsTableItems,
      boxesTableItems,
      palletsTableItems,
      setsTableItems,
      productsStats,
      boxesStats,
      palletsStats,
    ]
  );

  useEffect(() => {
    if (!tabsInfo[selectedDisplayingMode]) return;

    const tableItems = [...tabsInfo[selectedDisplayingMode].table].slice(0, STATUS_LIMIT);

    const allItems = [];

    tableItems.reverse().forEach((item) => {
      allItems.unshift(item);
      switch (selectedDisplayingMode) {
        case displayModes.ITEMS:
          switch (item.Status) {
            case 'SUCCESS':
              addStatusToArray(validItems, setValidItems, item, setValidItems);
              break;
            case 'NOREAD':
              addStatusToArray(errorItems, setErrorItems, item, setValidItems);
              break;
            case 'DROPOUT':
              addStatusToArray(dropoutItems, setDropoutItems, item, setValidItems, validItems);
              break;
            default:
              addStatusToArray(notValidItems, setNotValidItems, item, setValidItems);
              break;
          }
          break;
        case displayModes.BOXES:
          switch (item.Status) {
            case 'SUCCESS':
              addStatusToArray(validBoxes, setValidBoxes, item);
              break;
            case 'NOREAD':
              addStatusToArray(errorBoxes, setErrorBoxes, item);
              break;
            default:
              addStatusToArray(notValidBoxes, setNotValidBoxes, item);
              break;
          }
          break;
        case displayModes.PALLETS:
          switch (item.Status) {
            case 'SUCCESS':
              addStatusToArray(validPallets, setValidPallets, item);
              break;
            case 'NOREAD':
              addStatusToArray(errorPallets, setErrorPallets, item);
              break;
            default:
              addStatusToArray(notValidPallets, setNotValidPallets, item);
              break;
          }
      }
    });

    setTableItems(allItems.slice(0, STATUS_LIMIT));

  }, [tabsInfo, selectedDisplayingMode]);

  useEffect(() => {
    if (lineStatus === 'STOP') {
      setTableItems([]);
      setValidItems([]);
      setErrorItems([]);
      setDropoutItems([]);
      setNotValidItems([]);
    }

    setStats(tabsInfo[selectedDisplayingMode].stats);
  }, [lineStatus, tabsInfo, selectedDisplayingMode, validItems]);

  useEffect(() => {
    resetTableFuncs.current = [setTableItems];
  }, [resetTableFuncs]);

  useEffect(() => {
    if (tableItems?.length > 0) {
      const newItem = tableItems[0];
      if (!lastAddedItem || newItem.Id > lastAddedItem.Id) {
        setLastAddedItem(newItem);
      }
    }
  }, [tableItems]);

  const onChange = (e) => {
    setChecked(e.target.value);
  };

  const generateTableByFilter = useCallback(() => {
    if (!tableItems?.length) return [];
    switch (selectedDisplayingMode) {
      case displayModes.ITEMS:
        switch (checked) {
          case "all":
            return tableItems;
          case "valid":
            return validItems;
          case "notvalid":
            return notValidItems;
          case "error":
            return errorItems;
          case "dropout":
            return dropoutItems;
          default:
            return [];
        }
      case displayModes.BOXES:
        switch (checked) {
          case "all":
            return tableItems;
          case "valid":
            return validBoxes;
          case "notvalid":
            return notValidBoxes;
          case "error":
            return errorBoxes;
          default:
            return [];
        }
      case displayModes.PALLETS:
        switch (checked) {
          case "all":
            return tableItems;
          case "valid":
            return validPallets;
          case "notvalid":
            return notValidPallets;
          case "error":
            return errorPallets;
          default:
            return [];
        }
    }

  }, [checked, tableItems, validItems, notValidItems, errorItems, dropoutItems]);

  const filteredData = useMemo(() => generateTableByFilter(), [generateTableByFilter]);
  console.log(filteredData, "filteredData");

  const generateRow = useCallback(({ index, style }) => {
    const item = filteredData[index];
    return (
      <TableRow
        key={`resultTableRow-${index}`}
        showPhotos={showPhotos}
        data={item}
        style={style}
        isNewItem={item?.Id === lastAddedItem?.Id}
      />
    );
  }, [filteredData, showPhotos, lastAddedItem]);

  return (
    <div className={s.results}>
      <div className={s.header}>
        <div className={s.filters}>
          <TooggleButton
            checked={checked === "all"}
            label={`Все (${stats.total || 0})`}
            name="filter"
            value="all"
            onChange={onChange}
            status={codeStatuses.INITIAL}
            styles={{ marginBottom: 0 }}
          />
          <TooggleButton
            checked={checked === "valid"}
            label={`Успешно (${stats.valid || 0})`}
            name="filter"
            value="valid"
            onChange={onChange}
            status={codeStatuses.VALID}
            styles={{ marginBottom: 0 }}
          />
          <TooggleButton
            checked={checked === "notvalid"}
            status={codeStatuses.INVALID}
            label={`Невалиден (${stats.notValid || 0})`}
            name="filter"
            value="notvalid"
            onChange={onChange}
            styles={{ marginBottom: 0 }}
          />
          <TooggleButton
            checked={checked === "error"}
            label={`Не считан (${stats.error || 0})`}
            status={codeStatuses.NOREAD}
            name="filter"
            value="error"
            onChange={onChange}
            styles={{ marginBottom: 0 }}
          />

          {selectedDisplayingMode === 1 && (
            <TooggleButton
              checked={checked === "dropout"}
              label={`Брак (${stats.dropout || 0})`}
              status={codeStatuses.DROPOUT}
              name="filter"
              value="dropout"
              onChange={onChange}
              styles={{ marginBottom: 0 }}
            />
          )}
        </div>
      </div>
      <div className={s.table}>
        <div className={s.tableHeader}>
          <button type="button" className={clsx(s.key, s.icon)} />
          <button type="button" className={clsx(s.key, s.date)}>
            Дата и время
          </button>
          <button type="button" className={clsx(s.key, s.scanner)}>
            Сканер
          </button>
          <button type="button" className={clsx(s.key, s.status)}>
            Статус
          </button>
          <button type="button" className={clsx(s.key, s.code)}>
            Код
          </button>
          {/* <button type="button" className={clsx(s.key, s.photo)}>
            Фото
          </button> */}
        </div>
        <div className={s.tableContent}>
          <List
            height={300}
            itemSize={40}
            width="100%"
            itemCount={generateTableByFilter()?.length}
          >
            {generateRow}
          </List>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ResultsTable);
